.footermainrow {
  background: linear-gradient(180deg, #281a5e 0%, #2f2262 100%);
  min-height: 380px;
  position: relative;
  overflow: hidden;
  @media (min-width: 1300px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
.footermainrow::before {
  content: '';
    position: absolute;
    top: 10rem;
    right: -20rem;
    width: 50%;
    height: 15rem;
    background: inherit;
    filter: blur(71px);
    z-index: 1;
}
.footermainrow .elipsetop {
  position: absolute;
  top: -20rem;
  right: -20rem;
  opacity: 0.3;
  max-width: 645px;
  max-height: 645px;
}
.footermainrow .elipsebottom {
  position: absolute;
  bottom: -9rem;
  left: -9rem;
  opacity: 0.5;
  max-width: 299px;
  max-height: 298px;
}
.footermainrow .logorow {
  display: flex;
  justify-content: center;
  margin: 3rem 0 0;
}
.logorow img {
  max-width: 166px;
  height: 48px;
}
.footermainrow .columns {
  max-width: 1200px;
  margin: 3rem auto 10px;
  display: flex;
  padding-bottom: 20px;
  gap: 4rem;
}
.footermainrow .columns .col {
  /* border-right: 1px solid #d8d8d8; */
}
.footermainrow .columns .col4 {
  border: none;
}
.footermainrow .columns .col1 li {
  font-family: "Sora";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  max-width: 250px;
  position: relative;
  color: #ffffff;
}
.footermainrow .columns .col1 {
  flex: 1;
  min-width: 352px;
  position: relative;
}
.footermainrow .columns .col2,
.footermainrow .columns .col3,
.footermainrow .columns.col4 {
  max-width: 23%;
  position: relative;
}

.footermainrow .columns .col1 ul {
  list-style-type: none;
}
.footermainrow .columns .col1 .location::before {
  content: "" !important;
  position: absolute !important;
  top: 4px;
  left: -31px;
  width: 16px !important;
  height: 16.4px;
  background-image: url("./commonimages/location.svg") !important;
  background-size: cover;
  margin-right: 5px;
}
.footermainrow .columns .col1 .location {
  margin-bottom: 2rem;
}
.footermainrow .columns .col1 .call {
  margin-bottom: 1rem;
}
/* call */
.footermainrow .columns .col1 .call::before {
  content: "" !important;
  position: absolute !important;
  top: 4px;
  left: -31px;
  width: 16px !important;
  height: 16.4px;
  background-image: url("./commonimages/call.svg") !important;
  background-size: cover;
  margin-right: 5px;
}
/* mail */
.footermainrow .columns .col1 .mail::before {
  content: "" !important;
  position: absolute !important;
  top: 4px;
  left: -31px;
  width: 16px !important;
  height: 16.4px;
  background-image: url("./commonimages/mail.svg") !important;
  background-size: cover;
  margin-right: 5px;
}
.footermainrow .columns .col2,
.footermainrow .columns .col3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.footermainrow .columns .col2 .col2head,
.footermainrow .columns .col3 .col2head {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
}
.footermainrow .columns .col2 .col2content,
.footermainrow .columns .col3 .col2content {
  font-family: "Sora";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
}
.allrights {
  background: #faad17;
  height: 61px;
}
.allrights .creditsrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}
.allrights .creditsrow span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  max-width: 340px;
  text-align: center;
  color: #000000;
}
.allrights .creditsrow div {
  max-width: 350px;
  display: flex;
  gap: 50px;
}
.colline {
  position: absolute !important;
  top: -37px !important;
  bottom: 0;
  right: 0;
  height: 193px;
  margin: auto 0;
  width: 1px !important;
  background-color: #d8d8d8 !important;
}
.footermainrow hr {
  color: #d8d8d8;
  max-width: 1118px;
  margin: 2rem auto;
}
.footerwrapper .fdetails {
  position: relative;
  /* background: linear-gradient(180deg, #281a5e 0%, #2f2262 100%); */
}
.footerwrapper .fdetails .fdrow {
  max-width: 1200px;
  margin: 0 auto 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerwrapper .fdetails .fcol1 div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerwrapper .fdetails .fcol1 {
  flex: 2;
}
.footerwrapper .fdetails .fcol2 {
  flex: 1;
  display: flex;
  justify-content: center;
}
.footerwrapper .fdetails .fcol2 .westfordlogo {
  max-height: 180px;
  z-index: 11;
}
.footerwrapper .fdetails .fcol1 div {
  max-width: 1200px;
  margin: 0 auto 2rem;
}
.footerwrapper .fdetails span {
  color: #fff;
  text-align: center;
  font-family: "Sora";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px; /* 200% */
}
.footerwrapper .fdetails .robo {
  position: absolute;
  width: 148.593px;
  height: 211.115px;
  top: -146px;
  right: -20px;
  z-index: 1;
}
.footerwrapper .fdetails .socaillinks {
  display: flex;
  justify-content: center;
  gap: 35px;
  margin: 0 0 2rem;
}
@media (max-width: 600px) {
  .footermainrow .columns .col2,
  .footermainrow .columns .col3 {
    min-width: 270px;
    margin-bottom: 2rem;
  }
  .colline {
    display: none;
  }
  .footermainrow .columns .col2,
  .footermainrow .columns .col3 {
    align-items: flex-start;
  }
}
@media (max-width: 830px) {
  .footermainrow .columns {
    flex-direction: column;
    gap: 25px;
  }
}
@media (max-width: 760px) {
  .testimonailswrapper .tcontent {
    justify-content: center;
  }
  .footermainrow .columns .col2,
  .footermainrow .columns .col3,
  .footermainrow .columns.col4 {
    max-width: 35%;
  }
  .footermainrow::before {
    content: '';
      position: absolute;
      top: 2rem;
    right: -8rem;
      width: 50%;
      height: 15rem;
      background: inherit;
      filter: blur(71px);
      z-index: 1;
  }
  .footermainrow .elipsetopmobile {
    position: absolute;
    top: -9rem;
    right: -9rem;
    opacity: 0.5;
    max-width: 299px;
    max-height: 298px;
  }
}
@media (max-width: 357px) {
  .footermainrow .columns .col1 {
    min-width: 300px;
  }
}
@media (max-width: 480px) {
  .testimonailswrapper .tcontent {
    flex-direction: column;
  }
  .testimonailswrapper .tcontent .col1 img {
    width: 100%;
    object-fit: cover;
  }
  /* responsive */
  .footermainrow .logorow {
    display: flex;
    justify-content: flex-start;
  }
  .footermainrow .mobcolumns {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 20px 0;
  }
  .footermainrow .mobcolumns .mobcol1 span {
    color: #fff;
    text-align: center;
    font-family: "Sora";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px; /* 208.333% */
    max-width: 308px;
  }
  .footermainrow .mobcolumns .mobcol1 img {
    max-width: 17px;
    margin: 20px 0;
  }
  .footermainrow .mobcolumns .mobcol1 {
    flex: 1;
    min-width: 352px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .accordion-body,
  .accordion-item button {
    background: #1e1e1e;
    color: #ffffff;
    border: none;
    border-radius: none !important;
    border-top-right-radius: none !important;
    border-top-left-radius: none !important;
  }
  .accordion-body span {
    color: #fff;
    font-family: "Sora";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 187.5% */
  }
  .accordion-item {
    border: none !important;
    background-color: transparent !important;
  }
  .accordion-button:not(.collapsed) {
    color: #fff !important;
    background-color: #1e1e1e !important;
    box-shadow: none !important;
  }
  .accordion-button:focus {
    border-color: none !important;
    box-shadow: none !important;
  }
  .accordion-header {
    color: rgba(255, 255, 255, 0.6);
    font-family: "Sora";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 187.5% */
  }
  #last-col3 {
    display: none;
  }
  .footermainrow .columns .col2,
  .footermainrow .columns .col3 {
    min-width: 46%;
  }
  .footermainrow .columns {
    flex-direction: row;
    gap: 25px;
  }
  .footermainrow hr {
    display: none;
  }
  .footerwrapper .fdetails .fcol1 div {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
  }
  .footerwrapper .fdetails .fcol1 div br {
    display: none;
  }
  .allrights .creditsrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  .allrights {
    height: auto;
    padding: 20px 0;
  }
  .allrights .creditsrow {
    gap: 20px;
  }
  .footerwrapper .fdetails .robo {
    max-width: 105px;
    top: -160px;
  }
  .footermainrow .elipsetopmobile {
    top: -2rem;
    right: -3rem;
    max-width: 130px;
    max-height: 130px;
  }
  .footermainrow .elipsebottom {
    bottom: -2rem;
    left: -3rem;
    opacity: 0.5;
    max-width: 110px;
    max-height: 110px;
  }
  .footerwrapper .fdetails .socaillinks {
    order: 1;
  }
}
