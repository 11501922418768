.csbannercol2 {
  display: flex;
  align-items: flex-end;
  position: relative;
  max-width: 504px !important;
}
.csbannercol2 img {
  z-index: 1;
  position: absolute;
  left: -13%;
}
.banner .bannerrowwrapper .csbannerbottom {
  position: absolute;
  max-width: 1338px;
  top: 0;
  left: 0;
  transform: translate(10%, 34%);
  z-index: 11;
}
@media (max-width: 1360px) {
  .banner .bannerrowwrapper .csbannerbottom {
    transform: translate(1%, 34%);
  }
}
/* csabout */
.csawrapper {
  background: #f6f4fb;
  min-height: 418px;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.csawrapper .csrow {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  min-height: 418px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.csawrapper .csrow .csacol2 {
  max-width: 491px;
}

.csawrapper .csrow .csacol1 {
  flex: 2;
  padding: 10px;
}
.csacol2 {
  flex: 1;
  padding: 10px;
}
.csaheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  /* or 122% */

  color: #303030;
}
.csawrapper .csrow .csacol1 .csaheading .csaheadsubspan {
  font-weight: 700;
}
.csacontent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  color: #1e1e1e;
}
/* dimg */
.csacol2 img {
  max-width: 512.38px;
}
/* crlabs */
.crwrapper {
  background: #f6f4fb;
  min-height: 418px;
}
.crwrapper .crrow {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  min-height: 418px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crwrapper .crrow .crcol2 {
  max-width: 491px;
}
@media (max-width: 640px) {
  .crcol2 img {
    width: 100%;
  }
  .crwrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .crrow {
    flex-direction: column;
    padding: 2rem 0;
  }
  .crwrapper .crrow .crcol2 {
    order: 2;
  }
}

.crwrapper .crrow .crcol1 {
  flex: 2;
  padding: 10px;
}
.crcol2 {
  flex: 1;
  padding: 10px;
}
.crheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  /* or 122% */

  color: #303030;
}
.crwrapper .crrow .crcol1 .crheading .crheadsubspan {
  font-weight: 700;
}
.crcontent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  color: #1e1e1e;
}
/* dimg */
.crcol2 img {
  max-width: 512.38px;
}

/* stem */
.stemwrapper .stemrow {
  background-image: url("./csimages/Group 811.svg");
  min-height: 508px;
  background-repeat: no-repeat;
  max-width: 1355px;
  margin: 4rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}
.stemwrapper .stemrow .stemrowcontent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px 0;
}
.stemwrapper .stemrow .title {
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px; /* 116.667% */
  max-width: 410px;
}
.stemwrapper .stemrow .title .bold {
  font-weight: 700;
}
.stemwrapper .stemrow .content {
  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 17.867px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.5px; /* 187.5% */
  max-width: 1149px;
  gap: 26px 0;
}
/* missonvision */
.missionwrapper .missionrow {
  background-image: url("./csimages/Mission\ &\ Vision.svg");
  min-height: 799px;
  background-repeat: no-repeat;
  max-width: 1275px;
  margin: 4rem auto;
}
.missionwrapper .missionrow .topcontent {
  max-width: 1044px;
  margin: 0 auto;
}
.missionwrapper .missionrow .col1 {
  max-width: 343px;
}
.missionwrapper .missionrow .col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.missionwrapper .missionrow .topcontent .missiontitle {
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
}
.missionwrapper .missionrow .topcontent .mcontent {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.missionwrapper .missionrow .vimgcol {
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.missionwrapper .missionrow .vimgcol img {
  max-width: 300px;
}
.missionwrapper .missionrow .col2 li {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  position: relative;
}
.missionwrapper .missionrow .col2 li::before {
  content: "";
  position: absolute;
  top: 4px;
  left: -25px;
  width: 16px;
  height: 16.4px;
  background-image: url("./csimages/Group\ 623.svg");
  background-size: cover;
  margin-right: 5px;
}
.missionwrapper .missionrow .col2 ul {
  list-style-type: none;
}
/* reoson */
.reosonwrapper .reosontitlerow {
  max-width: 1200px;
  margin: 2rem auto;
  display: grid;
  place-items: center;
}
.reosonwrapper .reosontitlerow span {
  color: #303030;
  text-align: center;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 138.889% */
  max-width: 680px;
}
.reosonwrapper .reosontitlerow span span {
  font-weight: 700;
}
.reosonwrapper .reosonsrow {
  max-width: 1200px;
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.reosonwrapper .reosonsrow .reosons {
  width: 379.937px;
  max-width: 379.937px;
  height: 69.784px;
  flex: 1 0 379px;
  border-radius: 9.289px;
  background: linear-gradient(289deg, #1e1152 -5.19%, #47368b 79.29%);
  box-shadow: 8.51472px 6.96659px 15.48131px 0px rgba(121, 106, 184, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.reosonwrapper .reosonsrow .reosons span {
  color: #fff;
  font-family: "Montserrat";
  font-size: 18.578px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.126px; /* 108.333% */
}
.digitalwrapper .digitalcol1 .bookbutton {
  width: 290px;
  height: 48px;
  border-radius: 5px;
  background: #faad17;
  border: none;
  margin: 2rem 0;
}
/* why choose coding school */
.whycswrapper {
  background: linear-gradient(
    180deg,
    #f6f4fb 0%,
    rgba(246, 244, 251, 0.29) 100%
  );
  min-height: 657px;
}
.whycswrapper .whycstitlerow {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whycswrapper .whycstitlerow span {
  color: #303030;
  text-align: center;
  font-family: "Montserrat";
  font-size: 36.364px;
  font-style: normal;
  font-weight: 400;
  line-height: 42.424px; /* 116.667% */
  margin: 2rem auto;
}
.whycswrapper .whycstitlerow span span {
  font-weight: 700;
}
.whycswrapper .whycscardrow {
  max-width: 1200px;
  margin: 2rem auto;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}
.whycswrapper .whycscardrow .whycscards {
  border-radius: 18.182px;
  background: #fff;
  width: 267.677px;
  max-width: 267.677px;
  flex: 1 0 267px;
  min-height: 416.162px;
  padding: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
}
.whycswrapper .whycscardrow .whycscards .cstitle {
  color: #000;
  font-family: "Montserrat";
  font-size: 18.182px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.283px; /* 155.556% */
  /* margin-bottom: 30px; */
  display: flex;
}
.whycswrapper .whycscardrow .whycscards .cscontent {
  color: #000;
  font-family: "Montserrat";
  font-size: 16.162px;
  font-style: normal;
  font-weight: 400;
  line-height: 30.303px; /* 187.5% */
  max-width: 196.97px;
  display: flex;
}
.whycswrapper .whycscardrow .whycscards .topimg {
  position: absolute;
  top: -27px;
  left: -28px;
}
.whycswrapper .whycscardrow .whycscards .btmimg {
  position: absolute;
  bottom: -14px;
  right: -15px;
}
/* csfuture */
.csfuturewrapper .csfuturerow1 {
  max-width: 1200px;
  margin: 2rem auto;
  position: relative;
}
.csfuturewrapper {
  position: relative;
  padding: 4rem 0;
}
.csfuturewrapper .bgimage {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-8%, -22%);
  z-index: 1;
}
@media (min-width: 1300px) {
  .csfuturewrapper .bgimage {
    min-width: 1347px;
  }
}
.csfuturewrapper .csfuturerow1 span {
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 138.889% */
  max-width: 598px;
}
.csfuturewrapper .csfuturerow1 span span {
  font-weight: 700;
}
.csfuturewrapper .csfuturerow2 {
  max-width: 1200px;
  margin: 2rem auto;
  background: #f6f4fb;
  min-height: 383px;
  display: flex;
  /* align-items: center; */
}
.csfuturewrapper .csfuturerow2 .fcol1 {
  margin: 3rem;
  max-width: 615px;
}
.csfuturewrapper .csfuturerow2 .fcol2 {
  width: 442px;
  max-width: 442px;
  min-height: 323px;
  border-radius: 12px;
  background: linear-gradient(289deg, #1e1152 -5.19%, #47368b 79.29%);
  box-shadow: 11px 9px 20px 0px rgba(121, 106, 184, 0.4);
  margin: auto 0;
}
.csfuturewrapper .csfuturerow2 .fcol1 span {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.csfuturewrapper .csfuturerow2 .fcol2 li {
  color: #fff;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 173.333% */
}
.csfuturewrapper .csfuturerow2 .fcol2 ul {
  margin: 2rem 0 0;
}
/* thrive */
.thrivewrapper {
  min-height: 686px;
  background: linear-gradient(
    180deg,
    #f6f4fb 0%,
    rgba(246, 244, 251, 0.29) 100%
  );
}
.thrivewrapper .thrivetitlerow {
  max-width: 1200px;
  margin: 2rem auto;
}
.thrivewrapper .thrivetitlerow span {
  color: #303030;
  text-align: center;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 138.889% */
  margin: 2rem auto;
}
.thrivewrapper .thrivetitlerow span span {
  font-weight: 700;
}
.thrivewrapper .thrivecontent {
  max-width: 1200px;
  margin: 2rem auto;
  min-height: 451px;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.2);
}
.thrivewrapper .thrivecontent .thrivecol {
  display: flex;
  flex-direction: column;
  margin: 4rem 0 0;
  padding-left: 40px;
}
.thrivecontent .thrivecol:nth-child(1) .tcontenttitle {
  display: flex;
  max-width: 280px;
}
.thrivecontent .thrivecol:nth-child(2) .tcontenttitle {
  display: flex;
  max-width: 248px;
}
.thrivecontent .thrivecol:nth-child(3) .tcontenttitle {
  display: flex;
  max-width: 271px;
}

.thrivewrapper .thrivecontent .thrivecol .tcontenttitle {
  color: #000;
  font-family: "Montserrat";
  font-size: 20.714px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px; /* 140% */
}
.thrivewrapper .thrivecontent .thrivecol .tcontent {
  color: #000;
  font-family: "Montserrat";
  font-size: 16.571px;
  font-style: normal;
  font-weight: 400;
  line-height: 31.071px; /* 187.5% */
  max-width: 321px;
  display: flex;
}
/* curriculum */
.curriculumwrapper .curriculumtitlerow {
  max-width: 1200px;
  margin: 2rem auto;
}
.curriculumwrapper .curriculumtitlerow .ctitle {
  color: #303030;
  text-align: center;
  font-family: "Montserrat";
  font-size: 36.364px;
  font-style: normal;
  font-weight: 400;
  line-height: 42.424px; /* 116.667% */
}
.curriculumwrapper .curriculumtitlerow .ctitle span {
  font-weight: 700;
}
.curriculumwrapper .curriculumtitlerow .ccontent {
  color: #303030;
  text-align: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  margin: 1rem 0 0;
}
.curriculumwrapper .boxrow {
  max-width: 1200px;
  margin: 2rem auto 7rem;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.curriculumwrapper .boxrow .cbox {
  max-width: 589.091px;
  min-height: 213.818px;
  border-radius: 19.636px;
  background: #f6f4fb;
  box-shadow: 0px 6.54545px 17.45454px 0px rgba(0, 0, 0, 0.08);
  flex: 1 0 589px;
  display: flex;
  align-items: center;
}
.curriculumwrapper .boxrow .cbox span {
  color: #000;
  font-family: "Montserrat";
  font-size: 17.455px;
  font-style: normal;
  font-weight: 400;
  line-height: 32.727px; /* 187.5% */
  max-width: 537px;
  padding-left: 10px;
}
/* responsive */
@media (max-width: 1450px) {
  .csbannercol2 img {
    width: 100%;
  }
}
@media (max-width: 1300px) {
  .missionwrapper .missionrow {
    background-position: center;
  }
  .crcol2 img {
    max-width: 490.38px;
}
}
@media (max-width: 800px) {
  .banner .bannerrow {
    flex-direction: column;
  }
  .csfuturewrapper .csfuturerow2 .fcol1 {
    margin: 0;
    padding: 3rem;
  }
}
@media (max-width: 900px) {
  .csawrapper .csrow,
  .crwrapper .crrow,
  .csfuturewrapper .csfuturerow2 {
    flex-direction: column;
  }
  .csawrapper .csrow .csacol2 {
    order: 1;
  }
  .csfuturewrapper .csfuturerow2 .fcol2 {
    margin: auto;
  }
  .csfuturewrapper .csfuturerow2 {
    padding-bottom: 2rem;
  }
}
@media (max-width: 640px) {
  .csacol2 img {
    width: 100%;
  }
  .csawrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .csrow {
    flex-direction: column;
    padding: 2rem 0;
  }
  .csawrapper .csrow .csacol2 {
    order: 2;
  }
  .thrivewrapper .thrivecontent {
    flex-direction: column;
    padding-bottom: 2rem;
  }
  .thrivewrapper .thrivecontent .thrivecol {
    align-items: center;
    padding: 20px;
    margin: 1rem 0 0;
  }
  .thrivecontent .thrivecol:nth-child(1) .tcontenttitle,
  .thrivecontent .thrivecol:nth-child(2) .tcontenttitle,
  .thrivecontent .thrivecol:nth-child(3) .tcontenttitle,
  .thrivewrapper .thrivecontent .thrivecol .tcontent {
    text-align: center;
    display: flex;
    max-width: 100%;
  }
  .missionwrapper .missionrow .col1 {
    display: flex;
    align-items: center;
  }
  .missionwrapper .missionrow {
    background-size: cover;
    min-height: 950px;
  }
}

@media (max-width: 600px) {
  .stemwrapper .stemrow {
    background-size: cover;
    min-height: 645px;
  }
  .curriculumwrapper .boxrow .cbox {
    max-width: 100%;
    min-height: 161px;
    padding: 25px 20px;
  }
  .missionwrapper .missionrow .topcontent {
    flex-direction: column;
  }
}
@media (min-width: 480px) and (max-width: 600px) {
  .missionwrapper .missionrow {
    background-image: url("./csimages/Group\ 624\ \(2\).svg");
    background-size: cover;
    min-height: 1211px;
  }
  .missionwrapper .missionrow .col2 {
    margin-top: 4rem;
  }
}
@media (max-width: 480px) {
  .csaheading,
  .crheading {
    font-size: 20px;
  }
  .csacontent,
  .crcontent,
  .csfuturewrapper .csfuturerow2 .fcol1 span,
  .curriculumwrapper .curriculumtitlerow .ccontent {
    font-size: 11px;
    line-height: 21px; /* 190.909% */
  }
  .stemwrapper .stemrow .title {
    font-size: 20px;
    line-height: 42px; /* 116.667% */
    text-align: center;
  }
  .stemwrapper .stemrow .content {
    text-align: center;
    font-size: 11px;
    line-height: 21px;
  }
  .stemwrapper .stemrow {
    background-image: url("./csimages/Group\ 629\ \(1\).svg");
    background-size: contain;
  }
  .reosonwrapper .reosontitlerow span {
    font-size: 20px;
    line-height: 30px;
  }
  .reosonwrapper .reosonsrow .reosons {
    width: 80%;
    max-width: 308px;
    height: 69.784px;
    flex: 1 0 80%;
  }
  .reosonwrapper .reosonsrow .reosons span {
    font-size: 15.06px;
    line-height: 16.315px;
  }
  .digitalcol2 {
    order: 1;
  }
  .whycswrapper .whycscardrow .whycscards {
    border-radius: 18.182px;
    width: 308.677px;
    max-width: 308.677px;
    flex: 1 0 267px;
    min-height: 196px;
    padding: 35px;
  }
  .whycswrapper .whycstitlerow span {
    font-size: 20px;
    line-height: 42.424px;
  }
  .whycswrapper .whycscardrow .whycscards .cstitle {
    font-size: 15.18px;
    line-height: 28.283px;
  }
  .whycswrapper .whycscardrow .whycscards .cstitle br {
    display: none;
  }
  .whycswrapper .whycscardrow .whycscards .cscontent {
    font-size: 13.16px;
    line-height: 23.16px; /* 187.5% */
    max-width: 100%;
  }
  .csfuturewrapper .csfuturerow1 span,
  .thrivewrapper .thrivetitlerow span,
  .curriculumwrapper .curriculumtitlerow .ctitle {
    font-size: 20px;
    max-width: 320px;
    line-height: 30px;
  }
  .csfuturewrapper .csfuturerow2 {
    flex-direction: column;
    max-width: 90%;
  }
  .csfuturewrapper .csfuturerow2 .fcol2 {
    width: 308px;
    max-width: 308px;
    margin: auto;
  }
  .csfuturewrapper .csfuturerow2 .fcol2 li {
    font-size: 12.234px;
    line-height: 20.391px;
    padding-bottom: 7px;
  }
  .csfuturewrapper .csfuturerow2 .fcol1 {
    padding: 2rem;
  }
  .thrivewrapper .thrivecontent .thrivecol .tcontenttitle {
    font-size: 15.18px;
    line-height: 29px;
  }
  .thrivewrapper .thrivecontent .thrivecol .tcontent,
  .curriculumwrapper .boxrow .cbox span,
  .missionwrapper .missionrow .topcontent .mcontent {
    font-size: 12px;
    line-height: 22px;
  }
  .missionwrapper .missionrow .col2 li {
    font-size: 11px;
    line-height: 16px;
    padding-bottom: 5px;
  }
  .missionwrapper .missionrow .topcontent .missiontitle {
    font-size: 25px;
    line-height: 44px;
  }
  .curriculumwrapper .curriculumtitlerow {
    justify-content: center;
    margin-top: 4rem;
  }
  .missionwrapper .missionrow {
    background-image: url("./csimages/Group\ 624\ \(2\).svg");
    background-size: contain;
    min-height: 1036px;
  }
  .missionwrapper .missionrow .topcontent {
    max-width: 352px;
  }
  .missionwrapper .missionrow .vimgcol img {
    max-width: 227px;
  }
  .missionwrapper .missionrow .col1 {
    margin-top: 45px;
  }
  .missionwrapper .missionrow .col1 img {
    max-width: 250px;
  }
}
