.robowrapper {
  padding: 5rem 0 1rem;
}
.robochoosecard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.robochoosecard .subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height, or 145% */

  color: #000000;
}
.robochoosecard .subcontent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  min-height: 84px;
  color: #1e1e1e;
}
.robochoosecard {
  position: relative;
}
.robochoosecard img {
  position: absolute;
  left: 34%;
  top: 8%;
  transform: translate(-50%, -50%);
}
/* programs */
.programsrow {
  max-width: 1200px;
  margin: 0 auto 2rem !important;
  position: relative;
}
.programsrow img {
  position: absolute;
  width: 100px;
  height: 100px;
  right: 0;
  /* right: -24px; */
  top: -19px;
}
.programsrow .title {
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 122.222% */
  padding: 0;
}
.programsrow.title.bold {
  font-weight: 700;
}
/* cards */
.programscardrow {
  max-width: 1200px;
  margin: 0 auto 2rem !important;
  display: flex;
  gap: 20px;
  min-height: 560px;
}

.programscardrow .card1 {
  border-radius: 12px;
  background: #fff;
  width: 369px;
  max-width: 369px;
  height: 512px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 20px 2rem;
  flex-direction: column;
  display: flex;
}
.programscardrow .card1:hover {
  width: 403.594px;
  max-width: 403.594px;
  padding: 20px 2rem;
  min-height: 560px;
  border-radius: 12px;
  background: linear-gradient(221deg, #b61db9 0%, #3d2c7c 92.34%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(34.5px);
  transform: translate(0, -24px);
  flex-direction: column;
  display: flex;
}
.programscardrow .card2 {
  width: 403.594px;
  max-width: 403.594px;
  padding: 20px 2rem;
  min-height: 560px;
  border-radius: 12px;
  background: linear-gradient(221deg, #b61db9 0%, #3d2c7c 92.34%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(34.5px);
  transform: translate(0, -24px);
  flex-direction: column;
  display: flex;
}
/* cardtitle */
.programscardrow .card1 .cardtitle {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
}
.programscardrow .card1 .price {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 72.222% */
}
.programscardrow .card1 .lessons {
  color: #303030;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.programscardrow .card1 .getstarted {
  color: #3a2a79;
  font-family: "Sora";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 200% */
  width: 299px;
  height: 48px;
  border-radius: 5px;
  border: 1px solid #3a2a79;
  background: #fff;
}

.programscardrow .card1 .modules {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
}
.programscardrow .card1 ul {
  padding-left: 19px;
}
.programscardrow .card1 li {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: lowercase;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.27);
}
/* .programscardrow .card1 li::before{
  content: '\2022';
  font-size: 24px;
  margin-right: 8px;
  } */
.programscardrow .card1 li::first-letter {
  text-transform: capitalize;
}
.programscardrow .card1:hover .cardtitle {
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
}
.programscardrow .card1:hover .price {
  color: #fff;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 72.222% */
}
.programscardrow .card1:hover .lessons {
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.programscardrow .card1:hover .getstarted {
  color: #fff;
  font-family: "Sora";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 200% */
  width: 327.031px;
  height: 52.5px;
  border-radius: 5px;
  border: 1px solid #3a2a79;
  background: #3a2a79;
  margin-top: 15px;
}

.programscardrow .card1:hover .modules {
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
}
.programscardrow .card1:hover ul {
  padding-left: 19px;
}
.programscardrow .card1:hover li {
  color: #fff;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: lowercase;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.27);
}
/* .programscardrow .card1 li::before{
  content: '\2022';
  font-size: 24px;
  margin-right: 8px;
  } */
.programscardrow .card1:hover li::first-letter {
  text-transform: capitalize;
}
/* postors */
.posterwrapper {
  min-height: 270px;
  background: linear-gradient(180deg, #302363 0%, #443386 51.11%);
}
.posterwrapper .postorsrow {
  max-width: 1200px;
  margin: 0 auto;
  min-height: 270px;

  /* background-color: aliceblue; */
}
.posterwrapper .postorsrow .postorscol {
  /* flex-direction: column; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.posterwrapper .postorsrow .postorscol .content {
  flex-direction: column;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.posterwrapper .postorsrow .postorscol .heading {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 100% */
}
.posterwrapper .postorsrow .postorscol .description {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}
/* stick */
.posterwrapper .postorsrow .postorscol .stick {
  width: 3px;
  height: 86px;
  background: #fff;
  margin-left: 81px;
}
/* rhobo class */
.rhoboclassrow {
  max-width: 1200px;
  margin: 2rem auto !important;
  padding-top: 2rem;
  position: relative;
}
.rhoboclassrow .mobile {
  width: 113.863px;
  position: absolute;
  top: -114px;
  right: 135px;
}
.rhoboclassrow span {
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 122.222% */
}
.rhoboclassrow span span {
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
}
.rhoboclassrow img {
  max-width: 944px;
  max-height: 470px;
  margin: 3rem 0;
}
.rhoboclassrow .bannerbutton {
  background: #faad17;
  border-radius: 5px;
  width: 290px;
  height: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */
  border: none;
  color: #1e1e1e;
}
/* frequently asked questions */
/* wrapper */
.questionsrow {
  max-width: 1200px;
  margin: 0 auto !important;
}

.questiontitle {
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
  text-align: center;
  margin: 2rem 0;
}

/* global */
.globalwrapper {
  max-width: 1460px;
  margin-bottom: 12rem;
  position: relative;
  margin-top: 8rem;
}
.globalwrapper .backgroundimg {
  position: absolute;
  top: -71px;
  right: 4px;
}
.globaltitlerow {
  max-width: 1200px;
  margin: 5rem auto 0 !important;
}
.globaltitlerow span {
  padding: 0;
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 122.222% */
}
.globaltitlerow span span {
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
}
/* slider */
.gsliderrow {
  margin: 3rem 0 3rem 6rem !important;
}
.globalslider {
  padding-left: 0;
  .slick-slide {
    margin: 10px 7px;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    padding: 0 48px 0 0;
    box-sizing: border-box;
    max-width: 498px;
    @media (max-width: 480px) {
      max-width: 500px;
      min-width: 498px;
      padding: 0 0 0 0;
    }
  }
  .slick-list {
    @media (max-width: 450px) {
      width: 107%;
    }
    @media (min-width: 450px) {
      width: 104%;
    }
    /* @media (min-width: 1350px) {
      width: 104%;
    } */
  }
  /* the parent */
  /* //   .slick-list {
  //     margin: 0 -27px;
  //   } */
}
.globalslider .globalcard {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
  max-width: 431px;
  min-width: 431px;
  height: 566px;
}
.carddetails {
  padding: 2rem;
  position: relative;
}
.carddetails div {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
}
.carddetails div .cardname {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}
.carddetails div .cardexp {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}
.carddetails ul {
  padding-left: 4rem;
}
.carddetails li {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}
.carddetails li::marker {
  color: #302363;
  width: 10px;
  height: 10px;
}
/* rating */
.carddetails .rating {
  width: 86px;
  height: 86px;
  background-color: #fbd23b;
  border-radius: 50%;
  position: absolute;
  top: -16%;
  right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carddetails .rating span {
  color: #1e1e1e;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 116.667% */
}
.carddetails .rating span span {
  font-size: 16px;
  font-weight: 400;
}
/* testimonails */
.testimonailswrapper {
  background: #f6f4fb;
  min-height: 680px;
}
.testimonailswrapper .testimonailstitle {
  position: relative;
  z-index: 111;
}
.testimonailswrapper .testimonailstitle span {
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
  text-align: center;
  margin: 3rem 0 0;
}
.testimonailswrapper .tcontent {
  max-width: 1200px;
  margin: 4rem auto 2rem !important;
  display: flex;
  gap: 60px;
}
.testimonailswrapper .tcontent .col2 {
  max-width: 439px;
}
.testimonailswrapper .tcontent .col1 {
  max-width: 526px;
}
.testimonailswrapper .tcontent .col1 img {
  max-width: 526px;
  height: 315px;
}
.testimonailswrapper .tcontent .col2 img {
  margin-bottom: 5px;
}
.testimonailswrapper .tcontent .col2 .quote {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.testimonailswrapper .tcontent .col2 .tname {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  align-items: center;
}
.testimonailswrapper .tcontent .col2 .tname .pname {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
}
.testimonailswrapper .tcontent .col2 .tname .pdesc {
  color: #1e1e1e;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.testimonailswrapper .tcontent .col2 .tname .trating {
  width: 79px;
  height: 37px;
  border-radius: 24px;
  background: rgba(250, 173, 23, 0.19);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.testimonailswrapper .tcontent .col2 .tname .trating span {
  color: #f9980b;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
}
.tslider {
  max-width: 1200px;
  margin: 0 auto !important;
}
/* media queries */
@media (max-width: 1200px) {
  .robochoosecard img {
    position: static;
    width: 100px;
    transform: translate(0, 0);
  }
  .robochoosecard {
    justify-content: flex-start;
  }
}
@media (max-width: 870px) {
  .testimonailswrapper .tcontent {
    flex-direction: column;
    align-items: center;
  }
  .testimonailswrapper {
    min-height: 1000px;
  }
}
@media (max-width: 800px) {
  .robochoosecard {
    align-items: center;
  }
  .whychoose {
    min-height: 1200px;
    padding-top: 2rem;
  }
  .robochoosecard .subcontent {
    text-align: center;
  }
  .posterwrapper .postorsrow {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
  }
  .posterwrapper .postorsrow .postorscol {
    flex-direction: column;
  }
  .posterwrapper .postorsrow .postorscol .stick {
    transform: rotate(90deg);
    margin-left: 0;
  }
  .testimonailswrapper .tcontent .col2 img {
    max-width: 50px;
  }
}
@media (max-width: 780px) {
  .programscardrow {
    justify-content: center;
  }
  .programscardrow.programscardrow .card2 {
    transform: translate(0, 0);
    margin: 20px 0;
  }
  .gsliderrow {
    margin: 3rem 0 !important;
  }
  .testimonailswrapper {
    min-height: 1000px;
  }
  #sticklast {
    display: none;
  }
  .rhoboclassrow .mobile {
    width: 87.863px;
    position: absolute;
    top: -114px;
    left: 0;
  }
  .rhoboclassrow .bannerbutton {
    max-width: 266px;
    margin: auto;
  }
}

@media (max-width: 480px) {
  .testimonailswrapper {
    min-height: 740px;
  }
  .mobtname {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .mobtname div {
    display: flex;
    flex-direction: column;
  }
  .mobtname div br {
    display: none;
  }
  /* why */
  .robochoosecard {
    padding: 0;
  }
  .robochoosecard .subtitle {
    font-size: 17.793px;
    line-height: 25.881px; /* 145.455% */
    margin: 10px 0;
  }
  .robochoosecard .subcontent {
    font-size: 14.558px;
    line-height: 22.646px; /* 155.556% */
    min-height: 40px;
  }
  .robochoosecard .geimage {
    max-width: 80px;
  }
  .robochoosecard .fcimage {
    max-width: 55px;
  }
  .robochoosecard .agaimage {
    max-width: 45px;
  }
  .robochoosecard br {
    display: none;
  }
  .whychoose {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .learningcontent .codestitle {
    padding: 1rem 0 1rem !important;
  }
  .whychoose {
    min-height: 790px;
  }
  .testimonailswrapper .testimonailstitle span {
    font-size: 20px;
    line-height: 44px;
  }
  .testimonailswrapper .tcontent .col2 .quote {
    font-size: 9.709px;
    line-height: 14.025px; /* 144.444% */
  }
  .testimonailswrapper .tcontent .col2 img {
    width: 31.825px;
    height: 18.34px;
  }
  .testimonailswrapper .tcontent {
    gap: 15px;
    margin: 1rem auto 2rem !important;
  }
  .testimonailswrapper .tcontent .col1 img {
    border-radius: 7.027px;
    box-shadow: 0px 3.51331px 9.36882px 0px rgba(0, 0, 0, 0.08);
    max-height: 189px;
  }
  .testimonailswrapper .tcontent .col1 iframe{
    border-radius: 12px !important;
  }
  .testimonailswrapper .tcontent .col2 .tname .pname {
    font-size: 8.63px;
    font-style: normal;
    font-weight: 600;
    line-height: 14.025px;
  }
  .testimonailswrapper .tcontent .col2 .tname .pdesc {
    font-size: 8.63px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.025px;
  }
  .testimonailswrapper .tcontent .col2 .tname .trating {
    width: 42.613px;
    height: 19.958px;
    border-radius: 12.946px;
    background: rgba(250, 173, 23, 0.19);
  }
  .testimonailswrapper .tcontent .col2 img {
    width: 14.406px;
    height: 14.406px;
    margin-top: 3px;
  }
  .testimonailswrapper .tcontent .col2 .tname .trating span {
    font-size: 8.63px;
    font-style: normal;
    font-weight: 600;
    line-height: 14.025px;
  }
  .testimonailswrapper .tcontent .col2 .brquote,
  .posterwrapper .postorsrow .postorscol br {
    display: none;
  }
  .testimonailswrapper .tcontent .col1 {
    padding: 0;
  }
  .posterwrapper .postorsrow .postorscol .description {
    font-size: 13.304px;
    line-height: 20.696px;
  }
  .posterwrapper .postorsrow .postorscol .heading {
    font-size: 23.652px;
    line-height: 23.652px;
    margin-bottom: 10px;
  }
  .rhoboclassrow span,
  .rhoboclassrow span span {
    font-size: 20px;
    line-height: 30.3px;
    text-align: center;
  }
  .roboclasswrapper {
    background: linear-gradient(180deg, #f6f4fb 0%, #f6f4fb 100%);
    padding: 2rem 0;
  }
  .rhoboclassrow {
    margin: 0 auto !important;
  }
  /* program card */
  .programsrow .title {
    font-size: 20px;
    line-height: 24.6px;
    text-align: center;
  }
  .programsrow .title br {
    display: none;
  }
  .programsrow img {
    position: absolute;
    width: 60px;
    height: 60px;
    right: 0;
    /* right: -24px; */
    top: -19px;
  }
  .programscardrow .card1 {
    border-radius: 12px;
    background: #fff;
    width: 211.726px !important;
    height: 293.777px;
    max-height: 293.777px;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 20px 1rem;
    flex-direction: column;
    display: flex !important;
  }
  .programscardrow .card1 br {
    display: none;
  }
  .programscardrow .card1:hover {
    width: 249.319px !important;
    height: 345.938px !important;
    min-height: 345.938px !important;
    max-height: 345px;
    border-radius: 7.413px;
    background: linear-gradient(204deg, #b61db9 2.65%, #3d2c7c 88.53%);
    padding: 20px 1.5rem;
    box-shadow: 0px 2.47099px 2.47099px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(21.31228256225586px);
    transform: translate(0, -24px);
    /*  */
  }
  .programscardrow .card1 .cardtitle,
  .programscardrow .card1 .lessons,
  .programscardrow .card1 .modules,
  .programscardrow .card1 li {
    font-size: 9.181px;
    line-height: 14.918px;
    margin-bottom: 10px;
  }
  .programscardrow .card1:hover .cardtitle,
  .programscardrow .card1:hover .lessons,
  .programscardrow .card1:hover .modules,
  .programscardrow .card1:hover li {
    font-size: 9.884px;
    line-height: 16.061px;
    margin-bottom: 10px;
  }
  .programscardrow .card1 .price {
    font-size: 20.656px;
    line-height: 14.918px;
    margin: 10px 0;
  }
  .programscardrow .card1:hover .price {
    font-size: 22.239px;
    line-height: 16.061px;
    margin: 13px 0;
  }
  .programscardrow .card1 .getstarted {
    font-size: 9px;
    line-height: 19.768px;
    width: 171.561px;
    height: 27.542px;
    border-radius: 2.869px;
    margin: 7px 0 15px;
  }
  .programscardrow .card1:hover .getstarted {
    font-size: 9.884px;
    line-height: 19.768px;
    width: 202.023px;
    height: 32.432px;
    border-radius: 3.089px;
    background: #3a2a79;
    margin: 10px 0 20px;
  }
  .programscardrow {
    min-height: auto;
    .slick-slide {
      margin: 7px;
    }
    .slick-track {
      display: flex;
    }
    .slick-slide {
      padding: 0 10px 0 0;
      box-sizing: border-box;
      max-width: 400px;
      width: 249px !important;
    }
    /* .slick-dots{
      bottom: -10px;
    } */
  }
  .programscardrow .card1:hover ul {
    padding-left: 10px;
  }
  .programscardrow .card1:hover .slick-slider .slick-list,
  .slick-slider .slick-track {
    margin: 2rem 0 0;
  }
  .slick-dots li.slick-active button:before {
    color: #302363;
  }
  .programwrapper {
    min-height: 480px;
  }
  /* global */
  .globalslider .globalcard {
    border-radius: 6.62px;
    box-shadow: 0px 3.30979px 8.82612px 0px rgba(0, 0, 0, 0.08);
    max-width: 237.754px;
    min-width: 237.754px;
    height: 312.224px;
  }
  .globalslider .globalcard .globalcardimage {
    width: 237.754px;
    height: 175.419px;
  }
  /* rating */
  .carddetails .rating {
    width: 47.44px;
    height: 47.44px;
    background-color: #fbd23b;
    border-radius: 50%;
    position: absolute;
    top: -14%;
    right: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carddetails .rating span {
    font-size: 9.929px;
    line-height: 11.584px;
  }
  .carddetails .rating span span {
    font-size: 8.826px;
    line-height: 11.584px;
  }
  .carddetails div .cardname {
    font-size: 9.929px;
    line-height: 15.446px;
  }
  .carddetails div .cardexp,
  .carddetails li {
    font-size: 8.826px;
    line-height: 15.446px;
  }
  .carddetails ul {
    padding-left: 3rem;
  }
  .carddetails li::marker {
    color: #302363;
    width: 10px;
    height: 10px;
  }
  .carddetails {
    padding: 1rem;
    position: relative;
  }
  .carddetails div {
    gap: 9px;
    margin-bottom: 5px;
  }
  .carddetails div img {
    max-width: 13px;
  }
  .globalwrapper {
    margin-bottom: 7rem;
    margin-top: 0;
  }
  .globaltitlerow span,
  .globaltitlerow span span {
    text-align: center;
    font-size: 20px;
    line-height: 30.3px;
  }
  .gsliderrow {
    margin: 0 !important;
  }
  .globalwrapper .backgroundimg {
    top: -35px;
    left: 0px;
    width: 100%;
    object-fit: cover;
  }
  .globalslider {
    padding-left: 0;
    .slick-slide {
      margin: 10px 7px;
    }
    .slick-track {
      display: flex;
      margin: 10px 0;
    }
    .slick-slide {
      box-sizing: border-box;
      max-width: 500px;
      min-width: 258px;
      width: 258px !important;
      /* padding: 0 0 0 0; */
    }
  }
}
@media (max-width: 350px){
.testimonailswrapper {
    min-height: 561px;
}}
/* ff */
@-moz-document url-prefix() {
  .programscardrow .card1 .cardtitle,
  .programscardrow .card1 .lessons,
  .programscardrow .card1 .modules,
  .programscardrow .card1 .price,
  .programscardrow .card1 .getstarted {
    margin: 10px 0;
  }
  @media (max-width:480px) {
    .programscardrow .card1 .cardtitle,
  .programscardrow .card1 .lessons,
  .programscardrow .card1 .modules,
  .programscardrow .card1 .price,
  .programscardrow .card1 .getstarted {
    margin: 5px 0;
  }
  }
}