.banner {
  background-color: #302363;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 700px;
}
.banner .bannerrowwrapper {
  position: relative;
  max-width: 1490px;
  margin: 0 auto;
}
.banner .bannerrowwrapper .bannertop {
  position: absolute;
  max-width: 899px;
  top: 0;
  left: 0;
  transform: translate(0%, 10px);
  z-index: 2;
}
.banner .bannerrowwrapper .bannerbottom {
  position: absolute;
  max-width: 1338px;
  top: 0;
  left: 0;
  transform: translate(1%, 23%);
}
.banner .bannerrow {
  max-width: 1200px;
  margin: 0 auto;
  min-height: 700px;
}
.banner .bannercol1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.banner .bannertitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 82px;
  color: #ffffff;
}
.banner .bannercontent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
  z-index: 1;
}
.banner .bannerbutton {
  background: #faad17;
  border-radius: 5px;
  width: 290px;
  height: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  z-index: 22;
  color: #1e1e1e;
  border: none;
}
.banner .bannercol2 {
  display: flex;
  align-items: center;
}
.banner .bannercol2 div {
  position: relative;
}
.banner .bannercol2 .imageone {
  position: relative;
  max-width: 560px;
  z-index: 1;
}
.banner .bannercol2 .imagetwo{
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-45%);
  z-index: 2;
  max-height: 613px;
    max-width: 340px;
}
/* learning programs */
.learningcontent span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  padding: 3rem 0;
  color: #303030;
}
.learningcontent .codestitle {
  padding: 8rem 0 2rem !important;
}
.learningcontent span span {
  font-weight: 900;
  font-family: "Montserrat";
}
.learningcards {
  max-width: 1200px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  margin: 0 auto !important;
  gap: 30px;
  justify-content: center;
  padding-bottom: 3rem;
}
.learningcards1,
.learningcards2,
.learningcards3,
.learningcards4 {
  border-radius: 12px;
  max-width: 576px !important;
  flex: 1 0 50% !important;
  width: 576px;
  min-height: 280px;
  padding: 2rem !important;
  position: relative;
}
.learningcards1 {
  background: linear-gradient(90deg, #fff4f8 0%, #ffc2d6 100%);
  display: flex;
}
.learningcards1 .lctitle1,
.learningcards2 .lctitle1,
.learningcards3 .lctitle1,
.learningcards4 .lctitle1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1e1e1e;
}
.learningcards1 .lccontent1,
.learningcards2 .lccontent1,
.learningcards3 .lccontent1,
.learningcards4 .lccontent1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #1e1e1e;
  display: flex;
  max-width: 320.44px;
  min-height: 84px;
}
.learningcards1 button,
.learningcards2 button,
.learningcards3 button,
.learningcards4 button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  width: 160px;
  height: 48px;
  border-radius: 5px;
  color: #ffffff;
  border: none;
}
.learningcards1 button {
  background: #fa6b9a;
}
.learningcards2 button {
  background: #8873f1;
}
.learningcards3 button {
  background: #3bc4e9;
}
.learningcards4 button {
  background: #59cb57;
}
.learningcards1 .col1,
.learningcards2 .col1,
.learningcards3 .col1,
.learningcards4 .col1 {
  flex: 2;
}
.learningcards1 .col2,
.learningcards2 .col2,
.learningcards3 .col2,
.learningcards4 .col2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.learningcards2 {
  background: linear-gradient(90deg, #f5f2ff 0%, #d3caff 100%);
  display: flex;
}
.learningcards3 {
  background: linear-gradient(90deg, #e7faff 0%, #baf0ff 100%);
  display: flex;
}
.learningcards4 {
  background: linear-gradient(98.3deg, #b4f4aa 1.55%, #e1ffdc 69.25%);
  display: flex;
}
/* why choose */
.whychoose {
  background-image: url("./homeimages/Group600.svg");
  max-width: 1429px;
  min-height: 531.99px;
  margin: 0 auto !important;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 3rem !important;
}

.choosecards {
  max-width: 1200px !important;
  margin: 0 auto !important;
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 20px;
}
@media (max-width: 480px) {
  .choosecards {
    flex-direction: column;
  }
}
.choosecards .choosecard1 {
  background: linear-gradient(288.6deg, #1e1152 -5.19%, #47368b 79.29%);
  box-shadow: 27px 27px 44px rgba(121, 106, 184, 0.35);
  border-radius: 12px;
  width: 390px;
  min-height: 193px;
  padding: 1.5rem;
}
@media (min-width: 1300px) {
  .choosecards .choosecard1,
  .choosecards .choosecard {
    max-height: 193px;
  }
}
@media (max-width: 1024px) {
  .banner .bannerrowwrapper {
    background-image: none;
  }
}
@media (max-width: 640px) {
  .bannerrow {
    flex-direction: column;
  }
  .banner .bannercol2 img {
    width: 100%;
  }
}
@media (max-width: 380px) {
  .learningcards1,
  .learningcards2,
  .learningcards3,
  .learningcards4 {
    height: auto;
  }
}
.choosecards .choosecard .subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #ffffff;
}
.choosecards .choosecard .subcontent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.choosecards .choosecard {
  background: #ffffff;
  border-radius: 12px;
  width: 390px;
  min-height: 193px;
  padding: 1.7rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.choosecards .choosecard:hover {
  background: linear-gradient(288.6deg, #1e1152 -5.19%, #47368b 79.29%);
  box-shadow: 27px 27px 44px rgba(121, 106, 184, 0.35);
  color: #ffff;
}
.choosecards .choosecard .subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
}
.choosecards .choosecard .subcontent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  color: #1e1e1e;
}
.choosecard:hover .subtitle {
  color: white;
}
.choosecard:hover .subcontent {
  color: white;
}
/* digital divide */
.digitalwrapper {
  background: #f6f4fb;
  min-height: 418px;
}
.digitalrow {
  max-width: 1200px !important;
  margin: 0 auto !important;
  display: flex;
  min-height: 418px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.digitalcol1 {
  flex: 2 !important;
  padding: 10px;
}
.digitalcol2 {
  flex: 1;
  padding: 10px;
}
.dheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #303030;
}
.dheading .dheadsubspan {
  font-weight: 700 !important;
}
.dcontent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  color: #1e1e1e;
}
/* dimg */
.digitalcol2 img {
  max-width: 512.38px;
}
@media (max-width: 640px) {
  .digitalcol2 img {
    width: 100%;
  }
  .digitalrow {
    flex-direction: column;
  }
}
/* provide */
.providewrapper {
  background: #f6f4fb;
  min-height: 683.43px;
  padding-bottom: 2rem;
}
.providewrapper .providerow {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 10px;
}
.provideheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #303030;
}
.provideheading .dheadsubspan {
  font-weight: 700 !important;
}
.providerow2 {
  background: #e5e2ec;
  min-height: 55px;
  max-width: 1200px;
  margin: 0 auto !important;
}
.providewrapper .providerow2 .pbutton:nth-child(1) {
  width: 29%;
}
.providewrapper .providerow2 .pbutton:nth-child(2) {
  width: 20.67%;
}
.providewrapper .providerow2 .pbutton:nth-child(3) {
  width: 20.67%;
}
.providewrapper .providerow2 .pbutton:nth-child(4) {
  width: 27.67%;
}
.providerow3 {
  background: #ffffff;
  min-height: 437.51px;
  display: flex !important;
  max-width: 1200px;
  margin: 0 auto !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
.pbutton {
  min-height: 55px;
  border: none !important;
  position: relative;
  border-radius: none !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  border-radius: 0;
  color: #1e1e1e !important;
  &:hover {
    background: #fff !important;
  }
}
.line {
  position: absolute !important;
  top: 0 !important;
  bottom: 0;
  right: 0;
  height: 21px;
  margin: auto 0;
  width: 2px !important;
  background-color: #48368b !important;
}
.providercol1 {
  flex: 1 !important;
  display: flex;
  padding-left: 1.5rem !important;
  max-width: 373px !important;
  align-items: center;
}
.providercol2 {
  flex: 2 !important;
  padding: 2rem 10px;
}
.providercol1 img {
  max-height: 355px !important;
  margin: 0 !important;
}
.providecontent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #1e1e1e;
}
/* discover */
.discoverwrapper {
  min-height: 400px;
}
.discoverrow {
  max-width: 1200px;
  margin: 2rem auto !important;
}
.discovertitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  margin-bottom: 10px;
  color: #303030;
}
.bold {
  font-weight: 700 !important;
}
.whatwedo {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height, or 123% */
  margin-bottom: 10px;
  text-align: center;

  color: #47368b;
}
.discovercontent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */
  max-width: 796.67px !important;
  text-align: center;
  margin: 0 auto;
  color: #1e1e1e;
}
.discoverrow2 {
  background: #f6f4fb;
  min-height: 400px;
  max-width: 1200px;
  margin: 7rem auto 2rem !important;
  position: relative;
}
@media (max-width: 480px) {
  .discoverrow2 {
    display: flex;
    flex-direction: column !important;
    /* background-image: url('./homeimages/Group\ 623\ \(1\).svg'); */
  }
}
.discoverrow2 img {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-8%, -22%);
  z-index: 1;
}
@media (min-width: 1300px) {
  .discoverrow2 img {
    min-width: 1347px;
  }
}
.cardrows1 {
  min-height: 90px;
  max-width: 442px;
  /* background: linear-gradient(288.6deg, #1e1152 -5.19%, #47368b 79.29%); */
  /* box-shadow: 11px 9px 20px rgba(121, 106, 184, 0.4); */
  border-radius: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  cursor: pointer;
  padding: 20px;

  color: #ffffff;
}
.cardrows2 {
  min-height: 90px;
  max-width: 442px;
  /* background: #ffffff; */
  /* box-shadow: 0px 4px 4px rgba(213, 209, 224, 0.47); */
  border-radius: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  cursor: pointer;
  padding: 20px;
  color: #000000;
}
.cardrows2:hover {
  background: linear-gradient(
    288.6deg,
    #1e1152 -5.19%,
    #47368b 79.29%
  ) !important;
  color: #fff;
}
.cardrows {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 3rem;
  padding-left: 3rem !important;
  max-width: 508px !important;
  position: relative;
  z-index: 222;
}
.cardrowss {
  display: flex;
  gap: 15px;
  padding: 3rem;
  padding-right: 3rem !important;
}
@media (max-width: 480px) {
  .cardrowss {
    padding-right: 0 !important;
  }
}
.cardrowss span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  color: #1e1e1e;
}
/* mc */
.mcwrapper {
  padding: 3rem 0;
}
.mcwrapper .mcrow {
  max-width: 1273px;
  margin: 0 auto;
  background-image: url("./homeimages/Group 601.svg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 799.63px;
  padding-top: 6rem;
}
@media (max-width: 1250px) {
  .mcwrapper .mcrow {
    background-position: center;
  }
}
@media (min-width: 700px) and (max-width: 1000px) {
  .mccontents li {
    padding-bottom: 10px !important;
  }
}
@media (min-width: 480px) and (max-width: 700px) {
  .mccontents li {
    padding-bottom: 10px !important;
    font-size: 14px !important;
    line-height: 22px !important;
  }
  .mcheading {
    font-size: 23px !important;
    line-height: 30px !important;
  }
}
@media (min-width: 480px) and (max-width: 510px) {
  .mcwrapper .mcrow {
    padding-top: 3rem;
  }
}
@media (min-width: 1000px) and (max-width: 1110px) {
  .mcwrapper .mcrow div {
    padding-left: 3rem;
  }
}
@media (max-width: 480px) {
  .mcwrapper .mcrow {
    padding: 1rem;
  }
}
.mcrow div {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.mcheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height, or 122% */

  text-align: center;
  color: #303030;
}
.mcbold {
  font-weight: 700;
}
.mccontents {
  max-width: 1017px !important;
  margin: 0 auto;
  list-style-type: none !important;
}
.mccontents li {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */
  padding-bottom: 15px;
  position: relative;
  color: #1e1e1e;
}
.mccontents li::before {
  content: "" !important;
  position: absolute !important;
  top: 4px;
  left: -21px;
  width: 16px !important;
  height: 16.4px;
  background-image: url("./homeimages/dot.svg") !important;
  background-size: cover;
  margin-right: 5px;
}
/* speaktoourteam */
.speakwrapper {
  min-height: 421.13px;
  background: #f6f4fb;
  margin-top: 6rem;
}
.speakwrapper .speakrow {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}
.speakrow .speakcol1 {
  flex: 1;
  position: relative;
}
.speakrow .speakcol2 {
  flex: 2;
  padding-bottom: 10px;
}
.speakrow .speakcol1 img {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, -8%);
}
@media (max-width: 1150px) {
  .speakrow .speakcol1 img {
    position: relative;
  }
}
@media (max-width: 480px) {
  .speakrow .speakcol1 img {
    width: 100%;
  }
}
.speakrow .speakcol2 .title {
  margin: 2rem 0;
}
.speakrow .speakcol2 .title span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height, or 150% */
  text-align: center;

  color: #1e1e1e;
}
.speakcol2 .form .formcol {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
@media (max-width: 1250px) {
  .speakcol2 .form .formcol {
    margin-top: 20px;
  }
}
.form input,.form .phoneinput {
  background: #ffffff;
  border-radius: 2px;
  height: 50px;
  /* max-width: 368px; */
  width: 368px;
  border: none;
  padding-left: 15px;
}
.speaksubmit button {
  background: #faad17;
  border-radius: 5px;
  max-width: 182px;
  height: 48px;
  margin: 0 auto;
  border: none;
  margin-top: 25px;
}
/* responsive banner */
/* 1000 */
@media (max-width: 1000px) {
  .mccontents{
    padding-left: 2rem !important;
  }
  .banner .bannercol1 {
    margin: 3rem 0 0;
   }
  .banner .bannercol2 div {
   margin: 2rem 0;
  }
  .banner .bannerrowwrapper .bannertop {
    display: none;
  }
  }
  /* 900 */
@media (max-width: 900px) {
  .digitalrow {
    flex-direction: column;
  }
  .speakrow .speakcol1{
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 480px) and (max-width: 1350px) {
  .whychoose {
    min-height: 607.99px;
    margin: 0 auto !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
}
@media (min-width: 480px) and (max-width: 800px) {
  .whychoose {
    min-height: 1007.99px;
  }
  .learningcontent .codestitle {
    padding: 9rem 0 2rem !important;
  }
  .choosecards {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width:480px) and (max-width:640px) {
  .pbutton{
    font-size: 13px !important;
  }
  .banner .bannercol2 div {
    margin: 6rem 0;
}
.banner .bannercol2 .imagetwo {
  max-height: 500px;
  max-width: 280px;
}
}
@media (min-width: 480px) {
  .providewrapper .providerow2 .pbutton .arrow {
    display: none;
  }
}
@media (max-width: 780px) {
  .bannerrowwrapper .bannerrow .bannercol1 {
    order: 2;
    padding-bottom: 35px;
  }
  .providerow3 {
    flex-direction: column;
  }
  .providercol1 {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}
@media (max-width: 480px) {
  .bannerrowwrapper .bannerrow .bannercol1 .bannertitle {
    color: #fff;
    font-family: "Montserrat";
    font-size: 38.478px;
    font-style: normal;
    font-weight: 700;
    line-height: 43.822px; /* 113.889% */
  }
  .bannerrowwrapper .bannerrow .bannercol1 .bannercontent {
    color: #fff;
    font-family: "Montserrat";
    font-size: 18.982px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.892px; /* 141.667% */
  }
  .bannerrowwrapper .bannerrow {
    background-image: url("./homeimages/Group\ 619.png");
    min-height: 679px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .bannerrowwrapper .bannerrow .bannercol2 img {
    display: none;
  }
  .banner .bannerrowwrapper .bannertop,
  .banner .bannerrowwrapper .bannerbottom {
    display: none;
  }
  /* learning program */
  .learningcontent span {
    font-size: 20px;
    line-height: 30.3px;
    max-width: 272px;
    margin: auto;
  }
  .learningcontent span span {
    font-size: 20px;
    line-height: 30.3px;
  }
  .learningcards1 .lctitle1,
  .learningcards2 .lctitle1,
  .learningcards3 .lctitle1,
  .learningcards4 .lctitle1 {
    font-size: 14.5px;
    line-height: 19.333px; /* 133.333% */
  }
  .learningcards1 .lccontent1,
  .learningcards2 .lccontent1,
  .learningcards3 .lccontent1,
  .learningcards4 .lccontent1 {
    font-size: 10.875px;
    min-height: 66px;
    line-height: 16.917px;
  }
  .learningcards .learningcards1,
  .learningcards .learningcards2,
  .learningcards .learningcards3,
  .learningcards .learningcards4 {
    border-radius: 7.25px;
    max-width: 348px !important;
    width: 348px;
    max-height: 169.167px;
    min-height: 169.167px;
  }
  .learningcards1 button,
  .learningcards2 button,
  .learningcards3 button,
  .learningcards4 button {
    width: 96.667px;
    height: 29px;
    border-radius: 3.021px;
    font-size: 9.667px;
    line-height: 16.917px; /* 175% */
  }
  .learningcards1 .col2 img,
  .learningcards2 .col2 img,
  .learningcards3 .col2 img,
  .learningcards4 .col2 img {
    max-width: 88px;
  }
  .learningcards1 .col1 br,
  .learningcards2 .col1 br,
  .learningcards3 .col1 br,
  .learningcards4 .col1 br {
    display: none;
  }
  .choosecards .choosecard {
    max-width: 307px;
    max-height: 152px;
    min-height: 152px;
    padding: 1.5rem;
  }
  .choosecards .choosecard .disbr {
    display: none;
  }
  .choosecards {
    justify-content: center;
    align-items: center;
  }
  /* whychoosecards */
  .choosecards .choosecard .subtitle {
    font-size: 17.374px;
    line-height: 25.272px;
  }
  .choosecards .choosecard .subcontent {
    font-size: 14.215px;
    font-weight: 400;
    line-height: 22.113px;
  }
  .whychoose {
    background-image: url("./homeimages/Group\ 629.svg");
    /* width: 1348.17px; */
    /* background: #f6f4fb; */
    /* border: 1px dashed #000000; */
    max-width: 1429px;
    min-height: 747.99px;
    background-position: center;
  }
  .learningcontent .codestitle {
    padding: 6rem 0 1rem !important;
    max-width: 100%;
  }
  .dheading {
    font-size: 20px;
    line-height: 30.289px;
  }
  .dcontent {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .digitalcol1 {
    padding-top: 28px;
  }
  .disbr {
    display: none !important;
  }
  .mcheading {
    font-size: 20px;
    line-height: 30px;
    text-align: start;
    padding-left: 30px;
  }
  .mccontents li {
    font-size: 10px;
    line-height: 12px; /* 120% */
    min-width: 287px;
  }
  .mcwrapper .mcrow {
    max-width: 1273px;
    margin: 0 auto;
    background-image: url("./homeimages/Group 624.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* min-height: 653.63px; */
    padding-top: 7.5rem;
  }
  .mccontents {
    max-width: 287px !important;
  }
  /* dscover */
  .discoverrow {
    padding: 1.5rem;
  }
  .discovertitle {
    font-size: 20px;
    line-height: 30px;
    text-align: start;
    color: #303030;
    max-width: 300px;
  }
  .whatwedo {
    font-size: 12px;
    line-height: 32px; /* 266.667% */
    max-width: 100px;
    text-align: start;
  }
  .discovercontent {
    font-size: 9px;
    line-height: 16px;
    /* max-width: 308.67px !important; */
    text-align: start;
    margin: 0;
  }
  .cardrows2 {
    max-width: 307px;
    min-height: 73.273px;
    border-radius: 8.362px;
    box-shadow: 0px 2.78733px 2.78733px 0px rgba(213, 209, 224, 0.47);
    font-size: 12.543px;
    line-height: 18.118px;
  }
  .cardrowss span {
    font-size: 11px;
    line-height: 22px;
  }
  .cardrowss {
    padding-left: 2.5rem !important;
  }
  /* speak */
  .speakrow .speakcol1 {
    display: none;
  }
  .speakrow .speakcol2 .title span {
    font-size: 18px;
    line-height: 48px;
  }
  .form input,.form .phoneinput {
    background: #ffffff;
    border-radius: 2px;
    height: 50px;
    /* max-width: 368px; */
    width: 266px;
    border: none;
    padding-left: 15px;
    color: #1e1e1e;
    font-family: "Montserrat";
    font-size: 10.12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.727px; /* 204.815% */
  }
  .speaksubmit button {
    margin-bottom: 3rem;
  }
  .provideheading {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
  }
  .providewrapper .providerow2 .pbutton {
    min-height: 55px;
    position: relative;
    border-radius: none !important;
    font-size: 16px !important;
    border-radius: 0;
    color: #1e1e1e !important;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .providercol1 img {
    max-height: 204px !important;
    max-width: 260px;
    margin: 20px !important;
  }
  .providerow3 {
    flex-direction: column;
  }
  .providercol1 {
    display: flex;
    justify-content: center;
  }
  .providecontent {
    font-size: 11px;
    line-height: 22px;
  }
  .providewrapper .providerow2 .pbutton:nth-child(1),
  .providewrapper .providerow2 .pbutton:nth-child(2),
  .providewrapper .providerow2 .pbutton:nth-child(3),
  .providewrapper .providerow2 .pbutton:nth-child(4) {
    width: 348px;
  }
  .providerow2,
  .providerow3 {
    max-width: 348px;
  }
  .line {
    display: none;
  }
  .providewrapper {
    padding-bottom: 3rem;
  }
  .mccontents{
    padding-left: 1rem !important;
  }
}

/* 380 */
@media (max-width: 380px) {
  .banner .bannerbutton {
    width: auto;
    height: auto;
  }
  .choosecards .choosecard {
    width: 100%;
  }

}

/* delay */
.cstdelay200 {
  animation-delay: 200ms;
}
.cstdelay500 {
  animation-delay: 500ms;
}
.cstdelay800 {
  animation-delay: 800ms;
}
/* firefox */
@-moz-document url-prefix() {
  .banner .bannercontent {
    margin: 2rem 0;
  }
}