/* banner */
.cntctbannerrowwrapper {
  background-image: url("../whycodingschool/csimages/Group 807.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
.cntctbanner {
  background-color: #302363;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 700px;
}
.cntctbanner .cntctbannerrowwrapper {
  position: relative;
  max-width: 1490px;
  margin: 0 auto;
}
.cntctbanner .cntctbannerrowwrapper .cntctbannertop {
  position: absolute;
  max-width: 899px;
  top: 0;
  left: 0;
  transform: translate(0%, 10px);
  z-index: 2;
}
.cntctbanner .cntctbannerrowwrapper .cntctbannerbottom {
  position: absolute;
  max-width: 1338px;
  top: 0;
  left: 0;
  transform: translate(1%, 23%);
}
.cntctbanner .cntctbannerrow {
  max-width: 1200px;
  margin: 0 auto;
  min-height: 700px;
}
.cntctbanner .cntctbannercol1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cntctbanner .cntctbannertitle {
  color: #fff;
  font-family: "Montserrat";
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 82px;
}
.cntctbanner .cntctbannercontent {
  color: #fff;
  font-family: "Montserrat";
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 82px;
}
.cntctbanner .cntctbannerbutton {
  background: #faad17;
  border-radius: 5px;
  width: 290px;
  height: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  z-index: 22;
  color: #1e1e1e;
  border: none;
}
.cntctbanner .cntctbannerrowwrapper .cntctcsbannerbottom {
  position: absolute;
  max-width: 1338px;
  top: 0;
  left: 0;
  transform: translate(6%, 34%);
  z-index: 11;
}
.cntctcsbannercol2 {
  display: flex;
  align-items: flex-end;
  position: relative;
  max-width: 504px !important;
}
.cntctcsbannercol2 img {
  z-index: 1;
  position: absolute;
  left: -14%;
}
@media (max-width: 1360px) {
  .cntctbanner .cntctbannerrowwrapper .cntctcsbannerbottom {
    transform: translate(1%, 34%);
  }
  .cntctcsbannercol2 img {
    position: relative;
    width: 100%;
    left: auto;
  }
}
@media (max-width: 1024px) {
  .cntctbanner .cntctbannerrowwrapper {
    background-image: none;
  }
  .cntctbanner .cntctbannerrow {
    flex-direction: column;
  }
  .cntctbanner .cntctbannercol1 {
    margin: 3rem 0;
  }
}
@media (max-width: 640px) {
  .cntctbannerrow {
    flex-direction: column;
  }
  .cntctbanner .cntctbannercol2 img {
    width: 100%;
  }
}
/*  */
.cntctbanner .cntctbannercol2 img {
  position: relative;
  z-index: 1;
}
.connectwrapper {
  min-height: 616.13px;
  background: #f6f4fb;
  /* margin-top: 6rem; */
}
.connectwrapper .connectrow {
  max-width: 1211px;
  min-height: 616.13px;
  margin: 0 auto;
  display: flex;
}
.connectrow .connectcol1 {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.connectrow .connectcol2 {
  flex: 2;
  /* padding-bottom: 10px; */
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.connectrow .connectcol1 img {
  /* position: absolute;
    top: 0;
    left: 0;
    transform: translate(10px, -8%); */
}
@media (max-width: 1150px) {
  .connectrow .connectcol1 img {
    position: relative;
  }
}

.connectrow .connectcol2 .title {
  margin: 2rem 0;
}
.connectrow .connectcol2 .title span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height, or 150% */
  text-align: center;

  color: #1e1e1e;
}
.connectcol2 .connectform .formcol {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.connectform .formcol input,
.connectform .formcol .phoneinput {
  background: #ffffff;
  border-radius: 2px;
  height: 50px;
  /* max-width: 368px; */
  width: 363px;
  border: none;
  padding-left: 15px;
}
.connectcol2 .connectform .formmsg {
  margin: auto;
}
.connectcol2 .connectform .formmsg input {
  border-radius: 1.976px;
  background: #fff;
  max-width: 750px;
  min-height: 131.423px;
  border: none;
  margin-top: 2rem;
  padding-bottom: 71px;
}
.connectsubmit button {
  background: #faad17;
  border-radius: 5px;
  max-width: 182px;
  height: 48px;
  margin: 0 auto;
  border: none;
  margin-top: 25px;
}
@media (max-width: 1250px) {
  .connectcol2 .connectform .formcol {
    margin-top: 20px;
  }
  .connectcol2 .connectform .formmsg input {
    max-width: 363px;
    margin: 1rem auto;
  }
  .connectsubmit button {
    margin-bottom: 20px;
  }
  .connectcol2 .connectform {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 680px) {
  .connectrow .connectcol1 {
    display: none;
  }
}
@media (max-width: 480px) {
  .connectrow .connectcol2 .title span {
    font-size: 20px;
    line-height: 47.431px;
  }
  .connectform .formcol input,
  .connectform .formcol .phoneinput {
    max-height: 41px;
  }
  .connectform .formcol input,
  .connectcol2 .connectform .formmsg input,
  .connectform .formcol .phoneinput {
    width: 90%;
  }
  .connectcol2 .connectform .formcol {
    gap: 10px;
  }
  .connectcol2 .connectform .formcol,
  .connectcol2 .connectform .formmsg input 
  /* ,.connectform .formcol .phoneinput */ {
    margin-top: 10px;
    font-size: 11.755px;
    line-height: 20.152px;
  }
  .connectsubmit button {
    width: 144px;
    height: 37.978px;
    border-radius: 3.956px;
    margin-top: 10px;
  }
  .connectrow .connectcol2 .title {
    margin: 0 0;
  }
  .connectwrapper .connectrow,
  .connectwrapper {
    min-height: 560px;
  }
  .cntctbanner .cntctbannertitle {
    font-size: 38.48px;
    line-height: 48.48px;
  }
  .cntctbanner .cntctbannercontent {
    font-size: 21px;
    line-height: 82px;
  }
  .cntctbanner .cntctbannerrowwrapper .cntctbannertop {
    display: none;
  }
  .cntctbanner .cntctbannercol1 {
    margin: 0rem 0;
  }
  .mobbuttonwrap {
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    bottom: 25px;
  }
  .cntctbanner,
  .cntctbanner .cntctbannerrow {
    min-height: 570px;
  }
  .cntctcsbannercol2,
  .cntctbanner .cntctbannercol1 {
    padding: 0;
  }
}
/* ff */
@-moz-document url-prefix() {
  .cntctbanner .cntctbannertitle {
    margin: 1rem 0;
  }
  .cntctbanner .cntctbannercontent {
    margin: 1rem 0 10px;
  }
}