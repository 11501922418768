.navbar .button1 {
  width: 182px;
  height: 48px;
  background: #ec285b;
  border-radius: 5px;
  /* font-family: "Montserrat"; */
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
}
.navbar .button1:hover{
  background: #302363;
}
.navbar .toggle {
  display: flex;
  gap: 20px;
}
@media (max-width: 480px) {
  .navbar .button1 {
    width: 128.443px;
    height: 33.875px;
    color: #fff;
    font-family: "Montserrat";
    font-size: 11.292px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.583px; /* 200% */
  }
}
.links {
  font-family: "Montserrat";
  color: #302363;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 200% */
text-decoration: none;
}
.droplinks {
  color: #303030;
font-family: 'Montserrat';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.droplinks:hover{
  color: #fff;
}
@media (min-width:1024px) {
  .bigsc{
    display: none;
  }
}