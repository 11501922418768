/* banner */
.ourprgrmbannerrowwrapper {
  background-image: url("../contactus/contactimg/Group\ 624\ \(1\).svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
@media (max-width: 1024px) {
  .cntctbanner .ourprgrmbannerrowwrapper {
    background-image: none;
  }
}
.learnwrapper .learnrow {
  background-image: url("./opimages/Group\ 843.svg");
  min-height: 945px;
  max-width: 1368px;
  margin: 3rem auto;
  background-repeat: no-repeat;
  background-position: right;
  padding-top: 10rem;
}
.learnwrapper .learnrow .learntitlerow {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3rem;
}
.learnwrapper .learnrow .learntitlerow span {
  color: #303030;
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px; /* 116.667% */
  text-align: center;
}
.learnwrapper .learnrow .learntitlerow span span {
  font-weight: 700;
}
.learnwrapper .learnrow .learncards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-height: 570px;
  max-width: 1223px;
  margin: 0 auto;
}
.learnwrapper .learnrow .learncards .learncard {
  border-radius: 12px;
  background: #fff;
  width: 390px;
  flex: 1 0 390px;
  max-width: 390px;
  min-height: 290px;
  max-height: 290px;
  display: flex;
}
.learnwrapper .learnrow .learncards .learncard .lcardtitle {
  color: #000;
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 145.455% */
  margin-top: 10px;
  margin-bottom: 10px;
}
.learnwrapper .learnrow .learncards .learncard .lcardcontent {
  color: #000;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  margin-bottom: 10px;
  min-height: 112px;
  max-width: 354px;
}
.learnwrapper .learnrow .learncards .learncard div {
  max-height: 201px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* Innovator */
.innovatorwrapper {
  background: #f6f4fb;
  min-height: 683.43px;
  padding-bottom: 4rem;
}
.innovatorwrapper .innovatorrow {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 10px;
}
.innovatorheading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height, or 122% */
  text-align: center;
  color: #303030;
}
.innovatorheading .dheadsubspan {
  font-weight: 700 !important;
}
.innovatorrow2 {
  background: #e5e2ec;
  min-height: 55px;
  max-width: 1125px;
  margin: 0 auto !important;
}
.innovatorow3 {
  background: #ffffff;
  min-height: 1179px;
  display: flex !important;
  max-width: 1125px;
  margin: 0 auto !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  justify-content: center;
  gap: 20px;
  padding: 2rem 0;
}
.innovatorwrapper .innovatorow3 .innovatorcols {
  max-width: 340px;
  min-height: 1130px;
  border-radius: 23.111px;
  background: #f6f4fb;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 20px;
}
.innovatorbutton {
  /* background: #e5e2ec !important; */
  min-height: 55px;
  /* max-width: 299.71px !important; */
  border: none !important;
  position: relative;
  border-radius: none !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  border-radius: 0;

  color: #1e1e1e !important;
  /* border-right: 2px solid #000000 !important; */
  &:hover {
    background: #fff !important;
  }
}
.line {
  position: absolute !important;
  top: 0 !important;
  bottom: 0;
  right: 0;
  height: 21px;
  margin: auto 0;
  width: 2px !important;
  background-color: #48368b !important;
}
.innovatorcol1 {
  flex: 1 !important;
  display: flex;
  padding-left: 1.5rem !important;
  max-width: 373px !important;
  align-items: center;
}
.innovatorcol2 {
  flex: 2 !important;
  padding: 2rem 10px;
}
.innovatorcol1 img {
  max-height: 355px !important;
  margin: 0 !important;
}
.innovatorcontent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  color: #1e1e1e;
}
.innovatorwrapper .innovatorow3 .innovatorcols .levelbutton {
  width: 303px;
  height: 63px;
  border-radius: 13px;
  background: #3d2c7c;
  color: #fff;
  font-family: "Montserrat";
  font-size: 26.11px;
  font-style: normal;
  font-weight: 600;
  line-height: 35.95px; /* 137.687% */
  margin: 20px 0;
}
.innovatorwrapper .innovatorow3 .innovatorcols .programhighlightstitle {
  color: #000;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 35.95px; /* 179.75% */
  margin-bottom: 10px;
}
.innovatorwrapper .innovatorow3 .innovatorcols .phlists {
  list-style-type: none;
  padding-left: 25px;
}
.innovatorwrapper .innovatorow3 .innovatorcols .phlists li {
  padding-bottom: 12px;
  position: relative;
  color: #000;
  font-family: "Montserrat";
  font-size: 16.54px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.54px; /* 160.459% */
  max-width: 272px;
}
.innovatorwrapper .innovatorow3 .innovatorcols .phlists li::before {
  content: "";
  position: absolute;
  top: 4px;
  left: -25px;
  width: 16px;
  height: 16.4px;
  background-image: url("../whycodingschool/./csimages/Group\ 623.svg");
  background-size: cover;
  margin-right: 5px;
}
.innovatorwrapper .innovatorow3 .innovatorcols .locontent {
  color: #000;
  font-family: "Montserrat";
  font-size: 16.54px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.54px;
  max-width: 272px;
  margin-bottom: 10px;
}
.innovatorwrapper .innovatorow3 .innovatorcols .locontent span {
  font-weight: 600;
}
.innovatorwrapper .innovatorrow2 .innovatorbutton:nth-child(1) {
  width: 28.33%;
}
.innovatorwrapper .innovatorrow2 .innovatorbutton:nth-child(2) {
  width: 21.67%;
}
.innovatorwrapper .innovatorrow2 .innovatorbutton:nth-child(3) {
  width: 24.67%;
}
.innovatorwrapper .innovatorrow2 .innovatorbutton:nth-child(4) {
  width: 24.67%;
}
/* champ */
.innovatorow3 .basicprgrm{
  background: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 1125px;
  margin: 0 auto;
  /* gap: 20px; */
  padding: 2rem;
}
.innovatorow3 .basicprgrm .coursedesctitle{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  border-radius: 0;
  color: #1e1e1e ;
}
.innovatorow3 .basicprgrm .coursedesccontent{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  border-radius: 0;
  color: #1e1e1e ;
}
.innovatorow3 .basicprgrm .coursedesccontent li{
  margin-bottom: 10px;
}
@media (min-width: 480px) {
  .innovatorwrapper .innovatorrow2 .innovatorbutton .arrow {
    display: none;
  }
  
}
@media (max-width: 1324px) {
  .learnwrapper .learnrow {
    min-height: 988.99px;
    margin: 0 auto !important;
    /* margin-top: 17rem; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
}
@media (max-width: 1238px) {
  .learnwrapper .learnrow {
    min-height: 1328.99px;
    padding-top: 13rem;
  }
}
@media (max-width: 839px) {
  .learnwrapper .learnrow {
    min-height: 2186.99px;
    padding-top: 21rem;
  }
}
/* 480 */
@media (min-width:480px) and (max-width:640px) {
  .innovatorbutton{
    font-size: 13px !important;
  }
  
}
@media (max-width: 480px) {
  .learnwrapper .learnrow .learntitlerow span {
    font-size: 20px;
    line-height: 30px;
    max-width: 190px;
  }
  .learnwrapper .learnrow .learncards .learncard {
    border-radius: 7.815px;
    width: 254px;
    flex: 1 0 254px;
    max-width: 254px;
    min-height: 188.872px;
    max-height: 188.872px;
  }
  .learnwrapper .learnrow .learncards .learncard img {
    width: 33.215px;
    height: 41.031px;
  }
  .learnwrapper .learnrow .learncards .learncard .lcardtitle {
    font-size: 14.328px;
    line-height: 20.841px; /* 145.455% */
  }
  .learnwrapper .learnrow .learncards .learncard .lcardcontent {
    font-size: 11.723px;
    line-height: 18.236px; /* 155.556% */
    min-height: auto;
  }
  .learnwrapper .learnrow {
    background-image: url("./opimages/Group\ 878.png");
    min-height: 1257px;
    max-width: 368px;
    margin: 3rem auto !important;
    background-position: center;
    padding-top: 5rem;
  }
  .innovatorheading {
    font-size: 20px;
    line-height: 30px; /* 150% */
  }
  .innovatorheading .dheadsubspan {
    margin: auto;
    max-width: 275px;
    display: flex;
  }
  /* innovator */
  .innovatorow3 {
    flex-direction: column;
    align-items: center;
  }
  .innovatorwrapper .innovatorow3 .innovatorcols .levelbutton {
    width: 100%;
    height: 57.071px;
    border-radius: 11.776px;
    font-size: 23.653px;
    line-height: 32.566px;
  }
  .innovatorwrapper .innovatorow3 .innovatorcols .programhighlightstitle {
    font-size: 18.118px;
    line-height: 32.566px; /* 179.75% */
  }
  .innovatorwrapper .innovatorow3 .innovatorcols .phlists li,
  .innovatorwrapper .innovatorow3 .innovatorcols .locontent {
    font-size: 14.983px;
    line-height: 24.042px; /* 160.459% */
  }
  .innovatorwrapper .providerow2 .innovatorbutton:nth-child(1),
  .innovatorwrapper .providerow2 .innovatorbutton:nth-child(2),
  .innovatorwrapper .providerow2 .innovatorbutton:nth-child(3),
  .innovatorwrapper .providerow2 .innovatorbutton:nth-child(4) {
    width: 348px;
  }
  .innovatorwrapper .providerow2 .innovatorbutton {
    min-height: 55px;
    position: relative;
    border-radius: none !important;
    font-size: 16px !important;
    border-radius: 0;
    color: #1e1e1e !important;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .innovatorwrapper .providerow2 .innovatorbutton span{
    width: 92%;
    display: flex;
    justify-content: center;
  }
  .innovatorwrapper .innovatorow3 .innovatorcols {
    min-height: auto;
  }
}
